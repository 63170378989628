.map_container {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.map {
  width: 100vw;
  height: 100vh;
}

.fill {
  width: 100vw;
  height: 100vh;
}

.slider_container {
  display: flex;
  z-index: 2;
  justify-content: center;
  /* Centers horizontally */
  align-items: center;
  /* Centers vertically */
  position: absolute;
  top: 17%;
  left: 50%;
  /* 50% from the left */
  transform: translate(-50%, -50%);
}

.custom_slider {
  position: relative;
  top: 100px;
  right: 10px;
  width: 50px;
  height: 50px;
  z-index: 2;
}

.location_icon {
  font-size: 24px;
  color: #f00;
}

.slider_container {
  display: flex;
  z-index: 2;
  justify-content: center;
  /* Centers horizontally */
  align-items: center;
  /* Centers vertically */
  position: absolute;
  top: 15%;
  left: 50%;
  /* 50% from the left */
  transform: translate(-50%, -50%);
}

.Modal_title {
  text-align: center;
  padding: 2em 0 0em 0;
  font-size: small;
  font-weight: 600;
}

.nrega_section {
  background: white;
  border-radius: 5px;
  padding: 2em;
  z-index: 1;
  border-radius: 15px;
}

.nrega_title {
  margin-top: 1em;
  padding: 0 0 0.5em 0;
  font-size: small;
  font-weight: 600;
}

.header_buttons {
  display: flex;
  align-items: center;
  all: unset;
  position: absolute;
  margin-left: 6em;
  width: 80vw;
  top: 0%;
  margin-top: 7em;
  background-color: transparent;
}

.header_button {
  all: unset;
  background: white;
  padding: 7px 12px;
  border-radius: 12px;
  position: relative;
  margin: 1%;
  z-index: 1;
  font-size: 11px;
}

.header_secondary_buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0%;
  margin-top: 7.2em;
  margin-left: 0.5em;
  background-color: transparent;
}

.header_secondary_button {
  margin-bottom: 1.2em;
}


.plan_dropdown {
  background: white;
  border: 0px;
  font-size: 11.5px;
  width: 10em;
  border-radius: 10px;
  padding: 5px;
  z-index: 1;
  height: 2.1em;
}

.info_button {
  all: unset;
  background: white;
  padding: 7px 12px;
  border-radius: 7px;
  cursor: pointer;
  z-index: 1;
  margin: 7px;
  position: initial;
  right: 1px;
}

.footer_buttons_main {
  position: absolute;
  bottom: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 7em;
}

.footer_buttons_main_group {
  display: flex;
  justify-content: center;
  padding-top: 1em;
}

.footer_button {
  all: unset;
  padding: 6px 10px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
  margin: 7px;
  background-color: #498467;
  color: white;
  font-weight: medium;
  font-size: 11px;
}

/* .footer_planning{ */
/* display: flex; */
/* justify-content: flex-end; */
/* width: 100vw; */
/* margin-right: 2em; */
/* } */

/* .footer_planning_menu{
  display: flex;
  flex-direction: column;
  background-color: rgb(59, 32, 32);
  padding: 1em 0 0 0;
  border-radius: 2em;
  margin-right: 1em;
} */

.footer_planning {
  display: flex;
  justify-content: flex-end;
  margin-right: 1em;
}

.footer_planning_menu {
  display: flex;
  flex-direction: column;
  background-color: rgba(58, 58, 58, 0.4);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  padding: 1.4em;
  border-radius: 2em;
  margin-right: 1em;
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 1em;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.footer_button:focus {
  outline: 2px solid #3ed489;
}


.active_button {
  background-color: #3e98f7;
  color: white;
}

.chips_nrega_dropdown {
  display: flex;
  flex-flow: row wrap;
}